/* Navbar */
.navbar {
  background-color: #5c004d;
  /* background-color: #8B62BF; */
  padding: 10px 0;
}

.navbar-brand {
  font-weight: bold;
  color: white !important; /* Important to override Bootstrap's styles */
}

.nav-link {
  color: white !important; /* Important to override Bootstrap's styles */
}

.nav-link:hover {
  color: #bb93ef !important;
  transform: scale(1.03); /* Expand the element */
  transition: transform 0.2s; /* Add a smooth transition effect */
}

.nav-link.active {
  color: #bb93ef !important; /* Important to override Bootstrap's styles */
  font-weight: bold;
}

.sidebar-toggle {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.sidebar-toggle svg {
  transition: transform 0.2s ease-in-out;
}

.sidebar-open .sidebar-toggle svg {
  transform: rotate(180deg);
}

/* Sidebar animation for smooth show/hide */
.chat-list-sidebar {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  height: 100vh;
}

.chat-list-sidebar.collapsed {
  transform: translateX(-100%);
  opacity: 0;
}

.chat-list-sidebar.open {
  transform: translateX(0);
  opacity: 1;
}

@media (min-width: 768px) {
  .chat-column {
    flex-grow: 1;
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .chat-list-sidebar {
    position: fixed;
    width: 250px;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #120b1b;
  }

  .chat-list-sidebar.open {
    width: 85%;
  }

  .chat-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .chat-list-sidebar.open + .chat-column {
    display: none;
  }

  .chat-list-sidebar.collapsed + .chat-column {
    display: block;
  }
}

/* Add this class to control the hover effect for the dropdown */
.hover-dropdown:hover .dropdown-menu {
  display: block;
}

/* Additional styling for the dropdown items */
.hover-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  transform: translateY(10px);
  background-color: rgb(102, 1, 92) !important;
}

.hover-dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

/*Style the Dropdown Components*/

.dropdown-items {
  color: white !important;
  background-color: rgb(102, 1, 92) !important;
}

.dropdown-items:hover {
  text-decoration: underline;
  transform: scale(1.03);
  transition: transform 0.2s;
  border-radius: 5px;
}

.dropdown-items.active {
  color: #000 !important; /* Important to override Bootstrap's styles */
  background-color: #bb93ef !important;
  font-weight: bold;
}

.navbar .hover-dropdown.active {
  color: rgb(
    88,
    4,
    79
  ) !important; /* Important to override Bootstrap's styles */
  font-weight: bold;
}
