/* Background Image */
.login-background {
    background-image: url("../images/Login/lkc_image.jfif"); /* Replace with your image path or URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4; /* Adjust the opacity as needed */
    z-index: -1; /* Move the background image behind other content */
  }
  
/* Page Container */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Container for Login Box */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Login Box with Transparent Border */
.login-box {
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border: 2px solid rgba(255, 255, 255, 0.6); /* Semi-transparent border */
  border-radius: 15px;
  padding: 2rem 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for depth */
  max-width: 500px; /* Adjust the width as needed */
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Left-aligned Login Heading */
.login-title {
  width: 85%; /* Same width as the inputs */
  text-align: left;
  color: black;
}

.login-header-title {
  margin-bottom: 0px;
}

.login-header-content{
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Logo styling */
.login-logo {
  max-height: 45px;
  margin-right: 12px; /* Add some space between the logo and the title */
}

/* Style Login Form */
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-header-content {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Align logo and title vertically */
  justify-content: center; /* Center items horizontally */
  flex-wrap: nowrap; /* Prevent wrapping */
  cursor: pointer; /* Shows users it is clickable*/
}

/* Style Login Form Elements */
.login-form-elements {
  width: 85%;
  font-size: large;
  font-weight: bold;
  text-align: center;
  border: 2px solid rgb(168, 169, 169); /* Set border to black with 2px thickness */
  height: 35px;
  border-radius: 13px;
  box-shadow: none;
  background-color: rgb(212, 220, 220);
  margin: 1rem 0;
}

.password-criteria {
  color: black;
  font-size: small;
  text-align: left;
  width: 85%;
}

/* Style Login Button */
.login-button {
  color: white;
  background-color: rgb(86, 0, 78);
  border: none;
  border-radius: 13px;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 20px;
  outline: none;
  width: 25%;
  font-size: large;
  font-weight: bold;
  margin: 1rem 0;
}

.login-button:hover {
  background-color: rgb(111, 0, 100); /* Change the background color on hover if desired */
  transform: scale(1.03); /* Expand the element */
  transition: transform 0.2s; /* Add a smooth transition effect */
}

.title-style {
    background-color: rgb(86, 0, 78);
    color: darkslategray;
}

/* Target the anchor tag within the .signup-link class */
.signup-link a {
  color: rgb(111, 0, 100);
}

/* Optional: Style for hover effect */
.signup-link a:hover {
  color: rgb(153, 0, 140); /* Slightly different color for hover effect */
}