/* Set the background color for the entire page */
body {
    background-color: white;
    /* background-color: #D9D9D9; /* Light grey background */
}

/* Chat header styling */
.chat-header {
    background-color: #120B1B;
    color: white;
    padding: 16px;
    padding-bottom: 3px;
    font-weight: bold;
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack logo and title vertically */
    align-items: center; /* Center items horizontally */
}
  
/* Content within the header (logo and title) */
.chat-header-content {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Align logo and title vertically */
    justify-content: center; /* Center items horizontally */
    flex-wrap: nowrap; /* Prevent wrapping */
    cursor: pointer; /* Shows users it is clickable*/
}

/* Style for the 'Your Chats' text */
.your-chats {
  color: rgb(209, 209, 209);
  font-size: 0.9rem; /* Adjust size as necessary */
  font-weight: normal;
  text-align: left; /* Align the text to the left */
}

/* Logo styling */
.chat-logo {
    max-height: 40px;
    margin-right: 16px; /* Add some space between the logo and the title */
}

/* Title styling */
.chat-title {
    margin: 0;
    font-size: 1.25rem; /* Adjust font size to fit in a single line */
    white-space: nowrap; /* Prevent the text from wrapping */
}

/* Separator styling */
.title-separator {
    width: 100%; /* Full width */
    border: 0;
    height: 3px; /* Thickness of the separator */
    background-color: white; /* Color of the separator */
    margin-top: 8px; /* Space above the separator */
}  

.input-background {
    background-image: url("../../images/Login/lkc_image.jfif"); /* Replace with your image path or URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4; /* Adjust the opacity as needed */
    z-index: -1; /* Move the background image behind other content */
}

.input-field-styling {
    outline: none;
    border-radius: 13px;
    box-shadow: none;
    background-color: white;
    /* background-color: #D9D9D9; */
    border-color: #120B1B;
    border-width: 2px;
    padding: 1%;
    max-height: 20rem; /* Maximum height for the textarea */
    overflow-y: auto; /* Enable vertical scrollbar when content exceeds max-height */
    flex: 1;
    margin-right: 8px;
    padding-top: 1rem; /* Increase top padding */
    padding-bottom: 1rem; /* Increase bottom padding */
    resize: none; /* Disables manual resizing */
}

/* Default style for larger screens */
.input-field-styling::placeholder {
    font-size: 1rem; /* Regular size for larger screens */
}

/* Adjust placeholder size for smaller screens */
@media (max-width: 576px) {
    .input-field-styling::placeholder {
        font-size: 0.7rem; /* Reduce the font size when the screen is smaller */
        font-weight: bold; /* Make the placeholder text bold */
    }
}

.input-field-styling::-webkit-scrollbar {
    width: 0.4rem;
}

.input-field-styling::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}

.input-field-styling::-webkit-scrollbar-track {
    background: transparent;
}

.question-button {
    color: white;
    background-color: rgb(86, 0, 78);
    border: none;
    border-radius: 13px;
    box-shadow: none;
    cursor: pointer;
    padding: 10px 20px;
    outline: none;
    font-size: large;
    font-weight: bold;
}

.question-button:hover {
    background-color: rgb(111, 0, 100);
    transform: scale(1.03);
    transition: transform 0.2s;
}

.send-button {
    border-radius: 50%; /* Makes the button round */
    border: none; /* Removes the border */
    background-color: rgb(86, 0, 78); /* Your existing button color */
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

.send-button:hover {
    background-color: rgb(111, 0, 100); /* Darker shade when hovered */
    transform: scale(1.03); /* Slight increase in size when hovered */
}

.buffer-input-form {
    display: flex;
    align-items: center;
}
  
.question-button.send-button {
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}  

.response-box {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 12px;
    padding: 20px;
    position: relative;
}
  
.response-box::before {
    content: '';
    background: inherit;
    filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: inherit;
}

.chat-history {
    max-height: calc(100% - 120px);
    overflow-y: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px; 
}

/* .fixed-input-container {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px; 
    z-index: 100;
    left: 16.6667%; 
    width: calc(100% - 16.6667%); 
  }   */

  .fixed-input-container {
    /* width: calc(100% - 16.6667%);
    left: 16.6667%; */
    bottom: 0;
    max-width: 100%;
  }

  @media (max-width: 767.98px) { /* When the sidebar is hidden (xs and sm screens) */
    .fixed-input-container {
      width: 100%; /* Take full width when the sidebar is collapsed */
      left: 0;
    }
  }
  
/* Styles for chat messages */
.chat-message {
  padding: 8px 0; /* Add vertical padding to each message */
}

.message-sender {
  display: flex;
  align-items: center;
  margin-bottom: 0px; /* Space between sender and message content */
}

.user-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px; /* Size of the icon container */
  width: 30px; /* Size of the icon container */
  border-radius: 50%;
  background-color: rgb(86, 0, 78);
  margin-right: 8px; /* Space after the icon */
}

/* Small logo for Chat Anatomy messages */
.chat-logo-small {
  height: 30px; /* Adjust the size as needed */
  margin-right: 8px; /* Space after the logo */
}

.message-content > p {
    margin: 0px;
    padding: 0px;
} 

.message-content {
  padding-top: 5px;
}

.message-content > ol >li {
    padding-left: 1.1em;
    /* margin-left: 1em; */
}

.message-content > ul > li, .message-content > ul {
    padding-left: 1.225em;
    margin-left: 1.325em;;
}

.message-content > h3 {
    margin-bottom: .5em;
    font-weight: 600;
}


/* .message-content > h1, .message-content > h2, .message-content > h3, .message-content > h4, .message-content > h5, .message-content > h6 {
    margin-top: 1rem;
    margin-bottom: .5em;
    font-weight: 600;
}



.message-content > ul > li, .message-content > ol > li {
    margin-bottom: .5em;
    margin-top: .5em;
}
*/

  

.message-divider {
    border-top: 2px solid #596066; /* Style for horizontal row */
    margin-top: 10px; /* Space above the row */
    margin-bottom: 5px; /* Space below the row */
}

.sources-header {
    font-size: 1rem; /* Adjust the font size as needed */
    color: #596066; /* Grey text for the 'Sources used:' header */
    margin-bottom: 5px; /* Space below the header */
}

.buffer-input-form {
    margin: 20px;
}

.container-fluid.p-0.d-flex.flex-row {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    height: calc(100vh - 56px);
}

.chat-list-sidebar.p-0.d-flex.flex-column.justify-content-start {
    background-color: #120B1B;
    color: white;
    height: 100%;
    padding: 0;
}

.chat-list-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh; /* Full height for the sidebar */
    background-color: #120B1B;
    color: white;
    position: relative;
    overflow:hidden;
  }

/* Help button container to fix the button at the bottom */
.help-button-container {
    position: absolute;
    bottom: -10px;;
    left: 0;
    width: 100%;
    padding: 10px;
    margin:0;
    background-color: #120B1B; /* Same background as the sidebar */
    text-align: left;
  }

.model-stream-header {
    color: white;
    padding: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

}

.model-stream-sidebar{
    background-color: #120B1B;
    color: white;
}

.chat-list-item {
    color: white;
    background-color: #120B1B; /* Adjust to sidebar background color */
    border-color: #120B1B;
    white-space: nowrap; /* Prevent text from wrapping to next line */
    overflow: hidden; /* Hide text that goes beyond the width */
    position: relative; /* Needed for pseudo-element positioning */
    padding-right: 10px; /* Add padding to give space for icons */
}

.chat-list-item::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 30%; /* Width of the fade effect */
    background: linear-gradient(to right, rgba(18, 11, 27, 0) 0%, #120B1B 100%); /* Gradient from transparent to the background color of the sidebar */
    pointer-events: none; /* Allows clicking on the text underneath */
}


/* Change the fade effect when hovering over the item */
.chat-list-item:hover::after {
    width: calc(100% - 40px); /* Adjust the width of the gradient to stop before the icons */
}

/* Ensure icons are positioned correctly and are not affected by the fade */
.chat-item-icons {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    gap: 10px;
    visibility: hidden; /* Hide icons by default */
    display: flex; /* This will keep the icons in place */
}

/* Adjust icon size and remove background */
.chat-icon {
    color: white;
    height: 20px; /* New icon size */
    width: 20px; /* New icon size */
    cursor: pointer; /* Change cursor to pointer to indicate clickable */
}

/* Style for icons when hovered */
.chat-icon:hover {
    color: #ccc; /* Light gray color for hover state */
}

/* Show the icons on hover and bring them above the fade effect */
.chat-list-item:hover .chat-item-icons {
    visibility: visible;
    z-index: 2; /* Ensure icons are above the ::after pseudo-element */
}

.edit-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
    top: 0;
    left: 0;
    width: calc(100%); /* Ensure text input field takes up same space as chat list item*/
    z-index: 3; /* Above the fade effect */
}

.chat-list-item.editing .chat-item-icons {
    display: none; /* This will remove the icons from the document flow */
}

/* This will exclude the input from the fade effect */
.chat-list-item.editing::after {
    display: none;
}

.chat-list-item:hover, .chat-list-item:focus {
    color: rgb(235, 4, 211) !important;
    background-color: rgb(102, 1, 92) !important;
    border-radius: 10px;
    position: relative; /* Re-establish position context for the pseudo-element */
}

.list-group-padding {
    background-color: #120B1B;
    flex-grow: 1;
    padding: 5px;
    overflow-y: auto;
    max-height: calc(100vh - 160px); /* Adjust this height based on your header and button height */
}

/* NEW: Styling for the sources badges */
.badge {
    margin-right: 5px; /* Spacing between badges */
}

/* NEW: Styling for the horizontal line under assistant messages */
.chat-bubble.bot-chat-bubble hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

/* NEW: Adjustments to the bot chat bubble to accommodate sources */
.bot-chat-bubble {
    padding-bottom: 10px; /* Add space for the sources badges */
}

/* NEW: Badge styles for source information */
.badge-primary {
    background-color: #007bff; /* Blue background for primary badges */
    color: white;
}

.badge-secondary {
    background-color: #6c757d; /* Grey background for secondary badges */
    color: white;
}

/* Custom scrollbar styles */
/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(70, 0, 65);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(111, 0, 100);
}

/* Flavour Text for when no chat is loaded*/
.no-chats-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
  
.no-chat-logo {
    max-width: 100px; /* Adjust size as needed */
    margin-top: 6%;
    margin-bottom: 20px;
    display: block; /* Ensure the image is block-level to center it */
    margin-left: auto;
    margin-right: auto;
}
  
.no-chat-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
    text-align: center;
}

/* Styling for the model label */
.chat-column {
    position: relative; /* This is necessary for absolute positioning inside the column */
}

.model-label {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(88, 4, 79);
    color: white;
    padding: 5px 10px;
    border-bottom-right-radius: 5px;
    font-size: 0.9rem;
    z-index: 10; /* Ensure it's above other elements */
    font-weight: bold;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3); /* Small shadow facing down and left */
}

/* Styling for the conversational starters */
.starter-buttons-row .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    white-space: normal;
    text-align: center;
  }
  
  .starter-buttons-row .col {
    display: flex;
    flex-grow: 1;
  }
  
  .starter-buttons-row .row {
    display: flex;
    align-items: stretch;
  }
  
  .starter-buttons-row {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  