/* SimpleModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    backdrop-filter: blur(5px); /* Blurred background */
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align to the top */
    padding-top: 10vh; /* Give some space from the top */
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modal-container {
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 300px; /* Minimum width */
    max-width: 500px; /* Maximum width */
    margin: 0 auto; /* Center the card */
  }
  
  .modal-content {
    padding: 20px;
    text-align: center;
  }  