/* Ensures the title and subtitle are centered within the background image */
.title-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Ensure the title is above the blurred background */
}
  
/* Styles for the viewport-sized background container */
.viewport-background {
    height: 100vh; /* Set the height to 100% of the viewport */
    width: 100%; /* Set the width to 100% of the viewport */
    position: relative; /* Relative positioning for the title container */
    overflow: hidden; /* Ensures no scrollbars appear due to the pseudo-element */
}
  
/* Pseudo-element to create the blur effect */
.viewport-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../images/Login/lkc_image.jfif"); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(5px); /* Adjust the blur strength as needed */
    opacity: 0.6; /* Adjust the opacity to make the title stand out more */
}
  
/* Section below the image with the specified background and text color */
.below-image-section {
    background-color: #120B1B;
    color: white;
    padding: 20px 0; /* Add some padding */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}
  
/* Styles for the main title */
.main-title {
    font-size: 3em; /* Adjust the size as needed */
    font-weight: bold; /* Bold font weight */
    text-align: center;
    color: rgb(86, 0, 78); /* The color for headers */
}
  
/* Styles for the subtitle */
.sub-title {
    font-size: 1.5em; /* Adjust the size as needed */
    font-weight: normal; /* Normal font weight */
    text-align: center;
    color: rgb(86, 0, 78); /* The color you specified for headers */
} 

/*Below Image Title Styles*/
.below-image {
    width: calc((100%/24)*22);
    color: rgb(255, 0, 255);
    background-color: rgb(255, 4, 255);
    height: 4px;
    margin: auto;
}

.below-image-title {
    font-size: 2.3em; /* Adjust the size as needed */
    font-weight: normal; /* Normal font weight */
    color: rgb(235, 4, 211);
    margin-left: calc(100%/24);
}

.content-title-col {
    margin-top: 10px;
    margin-bottom: 10px;
}

.content-column {
    margin-left: calc(100%/24);
    margin-right: calc(100%/24);
    box-sizing: border-box; /* This ensures that padding is included in the width */
}

.content-column-wide {
    margin-left: calc(100%/24);
    box-sizing: border-box; /* This ensures that padding is included in the width */
}

.content-column-wide-image {
    width: calc((100%/12)*11)
}

.content-paragraph {
    width: 110%;
}

.content-div {
    justify-content: center;
}

/* Custom scrollbar styles */
/* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(111, 0, 100);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(140, 0, 129);
}

/* Image Styling */
/* Specific class for responsive images */
.responsive-image {
    width: calc((100%/12)*7);
    height: auto;
}

.responsive-image-wide {
    display: block; /* Ensures the image is treated as a block-level element */
    max-width: 90%; /* Makes the image responsive */
    height: auto; /* Keeps the aspect ratio of the image */
    margin: 0 auto; /* Centers the image horizontally within its container */
}
/* Just to center content*/
.justify-content-center {
    justify-content: center;
  }
  
/* Button Styles */
.dashboard-navigation-button {
    width: 80%;
    height: 100px; /* Adjust the height as needed */
    border: 2px solid white; /* Make the border thicker */
    color: white;
    font-size: 1.5rem; /* Increase the font size */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem; /* Spacing between buttons and next content */
    margin-top: 1rem; /* Spacing between buttons and previous content */
    transition: transform 0.2s, border-width 0.2s; /* Smooth transition for hover effect */
}
  
.dashboard-navigation-button:hover {
    transform: scale(1.05); /* Slightly expand button on hover */
    cursor: pointer;
    border-width: 4px; /* Make border even thicker on hover */
    text-decoration: none; /* Remove underline from link text on hover */
}  