/* WorkInProgress.css */
.work-in-progress-container {
    margin: 4% auto;
    width: 92%; /* Adjust the width so the total (margin + width) doesn't exceed 100% */
}
  
.work-in-progress-column {
    padding: 20px;
    text-align: center;
    border-style: dashed;
    border-width: 6px;
}

.icon {
    margin-bottom: 20px;
}

.header-text {
    margin-bottom: 20px;
    /* Color is set inline */
}

.content {
    color: black; /* Set default text color for content */
}

.content a {
    color: rgb(111, 0, 100);
}